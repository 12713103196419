import Cookies from 'js-cookie'
import { getUserInfoCache } from 'utils/user-info'
import storage from 'services/storage'

export const getPublicAttribute = () => {
  return {
    appsflyer_id: Cookies.get('afUserId') || '',
    idfa: '',
    androidid: '',
    imei: '',
    device_imei: '',
    oaid: '',
    is_push_authorized: null,
    airship_channel_id: null,
    fcm_id: '',
    product_line: 'Readink',
    platform: 'Web',
    apk_channel: '',
    app_install_time: null,
    visitor_id: '',
    login_id: getUserInfoCache()?.id || '',
    media_source: '',
    campaign_name: storage.get('campaign', true) || '',
    ad_set_name: '',
    ad_name: '',
    ad_click_time: null,
  }
}

export const track = (event: string, params: any): void => {
  // eslint-disable-next-line no-console
  console.log(
    `Sensors log:\nevent: ${event}\nparams: ${JSON.stringify(
      { ...getPublicAttribute(), ...params } ?? {}
    )}`
  )
  window?.sensors?.track(event, { ...getPublicAttribute(), ...params })
}

export const registerPage = (params = {}) => {
  window?.sensors?.registerPage({ params })
}
