import Cookies from 'js-cookie'
const KEY_USER_INFO = 'user-info'

export function getUserInfoCache() {
  return Cookies.get(KEY_USER_INFO)
    ? JSON.parse(Cookies.get(KEY_USER_INFO))
    : null
}

export function cleanUserInfoCache() {
  localStorage.removeItem(KEY_USER_INFO)
  Cookies.remove(KEY_USER_INFO)
}

export function setUserInfoCache(data) {
  localStorage.setItem(KEY_USER_INFO, JSON.stringify(data))
  Cookies.set(KEY_USER_INFO, JSON.stringify(data))
}

export function isLogin() {
  return Cookies.get(KEY_USER_INFO) !== null
}
