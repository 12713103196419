import axios from 'axios'
import { ATTRHOST } from 'config'
import { getQueryParams } from 'utils/query'
import { getUAInfo } from 'utils/user-agent'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

function createUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function getParamAndSetCache(key: string) {
  const value = getQueryParams(key) || sessionStorage.getItem(key) || ''
  if (!sessionStorage.getItem(key)) {
    sessionStorage.setItem(key, value)
  }
  return decodeURIComponent(value)
}

function getCookie(cname: string) {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim()
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
  }
  return ''
}

export async function post(data: any) {
  const headers = sessionStorage.getItem('headers')
    ? JSON.parse(sessionStorage.getItem('headers'))
    : {}

  let visitorId = localStorage.getItem('visitorId')

  if (!visitorId) {
    const fp = await FingerprintJS.load()
    const result = await fp.get()
    visitorId = result.visitorId
    localStorage.setItem('visitorId', visitorId)
  }

  const CustomInstallId =
    sessionStorage.getItem('CustomInstallId') || createUUID()
  if (!sessionStorage.getItem('CustomInstallId')) {
    sessionStorage.setItem('CustomInstallId', CustomInstallId)
  }

  const channelType = getParamAndSetCache('channelType')
    ? parseInt(getParamAndSetCache('channelType'), 10)
    : 20

  try {
    if (channelType === 20 && data.eventType === 4) {
      return false
    }

    return axios.post(
      ATTRHOST + '/api/v1/attributionplatformapp/attribution/report',
      {
        pixelId: getParamAndSetCache('pixelId'),
        installId: CustomInstallId,
        eventTime: new Date().getTime(),
        eventType: data.eventType,
        channelType,
        pageId: '',
        templateId: '',
        referrer: null,
        other: null,
        strategyId: '',
        appInformation: {
          ccid: visitorId ?? null,
          appName: 'Readink',
          bundleId:
            channelType === 20
              ? getUAInfo().os.name === 'iOS'
                ? 'com.app.qw.readink'
                : 'com.app.android.qw.readink'
              : 'www.readink.app',
          platformType: 8,
          appVersion: null,
          installTime: null,
        },
        campaignDetails: {
          isInMarketing: false,
          adId: getParamAndSetCache('ad_id') ?? '',
          adName: getParamAndSetCache('ad_name') ?? '',
          adsetId: getParamAndSetCache('adset_id') ?? '',
          adsetName:
            getParamAndSetCache('adset_name') ?? headers['referer'] ?? '',
          campaignId: getParamAndSetCache('campaign_id') ?? '',
          campaignName:
            getParamAndSetCache('campaign_name') ?? headers['referer'] ?? '',
          accountId: getParamAndSetCache('accountId') ?? '',
        },
        deviceInformation: {
          imei: null,
          idfa: null,
          idfv: null,
          androidId: null,
          deviceId: null,
          oaid: null,
          gaid: null,
          aaid: null,
          os: 'web',
          osVersion: null,
          deviceModel: null,
          language: navigator.language,
          screenWidth: window.screen.width,
          screenHeight: window.screen.height,
          ua: navigator.userAgent,
          ipv4: '',
          ipv6: '',
        },
        thirdPartyInformation: {
          fbp: getCookie('_fbp') ?? '',
          fbc: getCookie('_fbc') ?? '',
          fbLoginId: null,
          leadId: null,
        },
        userInformation: {
          userId: null,
          cId: '',
          ccId: visitorId ?? '',
          updateTime: null,
        },
        deepLink: data.deep_link_value
          ? data.deep_link_value + '&ci=' + CustomInstallId
          : '',
        eventSourceUrl: location.href,
      }
    )
  } catch (error) {
    console.error(error)
  }
}
