import type { NextWebVitalsMetric, AppContext } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import gtag from 'lib/gtag'
import PageLoading from 'components/page-loading'
import storage from 'services/storage'
import '../../styles/globals.css'
import { DefaultSeo } from 'next-seo'
import { getParameterByNames } from 'lib/url'
import { ATTRIBUTION_MEDIA_SOURCE_NAMES } from 'constant'
import { AppProvider } from '../contexts/appContext'
import App from 'next/app'
import { getQueryParams } from 'utils/query'
import * as attribution from 'services/api/attribution'
import { getPublicAttribute } from 'lib/sensors'
import * as sensors from 'lib/sensors'

function MyApp({ Component, pageProps, headers = {} }: any): JSX.Element {
  const router = useRouter()
  const handleRouteChange = () => {
    gtag.logPageView()
    // if (
    //   getQueryParams('channelType') === '3' ||
    //   storage.get('channelType', true) === '3'
    // ) {
    //   fbq.logEvent('track', 'PageView')
    //   fbq.addPixelEvent({ event_name: 'ViewContent' })
    // }
    window?.sensors?.quick('autoTrackSinglePage')
    sensors.registerPage(getPublicAttribute())
    sensors.track('page_view', {
      page_type: 'WEB',
      page_category1: '',
      page_category2: '',
      page_category3: '',
      page_category4: '',
      page_category5: '',
      page_name: router.route.replaceAll('/', '|'),
    })
    if (!sessionStorage.getItem('headers')) {
      sessionStorage.setItem('headers', JSON.stringify(headers))
      attribution.post({
        eventType: 5,
      })
      setTimeout(() => {
        attribution
          .post({
            eventType: 5,
          })
          .then(() => {
            setTimeout(() => {
              attribution.post({
                eventType: 4,
              })
            }, 1000)
          })
      }, 2000)
    }
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const handleDocumentClick = (e) => {
    const J_bottomDownloadPanel = document.querySelector(
      '#J_bottomDownloadPanel'
    ) as HTMLElement
    if (J_bottomDownloadPanel && !J_bottomDownloadPanel.contains(e.target)) {
      J_bottomDownloadPanel.style.display = 'none'
    }
  }

  useEffect(() => {
    handleRouteChange()
    document.body.addEventListener('click', handleDocumentClick)
    return () => {
      document.body.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    if (
      !storage.get('landing_page') ||
      getParameterByNames(router.asPath, ATTRIBUTION_MEDIA_SOURCE_NAMES)
    ) {
      storage.set('landing_page', router.asPath)
    }
    if (getQueryParams('channelType')) {
      storage.set('channelType', getQueryParams('channelType'), true)
    }
    storage.set('landing_page', router.asPath, true)
  }, [])

  return (
    <>
      <DefaultSeo
        titleTemplate={`%s - Readink`}
        defaultTitle={`Readink - Read Best Romance Webnovel & Fantasy Fiction Online | Good novel, Good reads.`}
        description="50,000+ Best Romance Web Novel & Fantasy Fiction for you to explore: Werewolf Romance, Billionaire Romance, Son-in-law's Revenge, Forced Marriage Romance Stories, Smut Stories and Y/A Teen Stories, etc. Your fantasy will be satisfied here whatever it is. Good novel, Good reads."
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: 'https://m.readink.app/',
          site_name: 'Readink',
        }}
        twitter={{
          handle: '@AnystoriesHub',
          site: '@AnystoriesHub',
          cardType: 'summary_large_image',
        }}
      />
      <AppProvider>
        <PageLoading>
          <Component {...pageProps} />
        </PageLoading>
      </AppProvider>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  return {
    ...appProps,
    headers: appContext?.ctx?.req?.headers,
  }
}

export default MyApp

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric): void {
  gtag.logEvent({
    action: name,
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  })
}
